<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction, putAction } from '@/command/netTool'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import { broadbandSituation, extraBed, smoking, wirelessWIFI, bedType as bedTypeArr, theWindow } from '@/utils/textFile'

export default {
  name: 'roomManageDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        roomArea: null,
        floorDistribution: null,
        maximumOccupancy: null,
        bedTypeArea: null
      },
      bed_type_arr: [],
      window_arr: [],
      labelGroup: [],
      hotelId: ''
    }
  },
  mounted() {
    const { id, hotelId } = this.$route.query
    this.hotelId = hotelId
    id &&
      api.command.getDetail
        .call(this, {
          url: `/hotelRoom/detail?id=${id}`
        })
        .then(res => {
          this.detail.bedType = res.bedType ? res.bedType.split(',') : []
        })
    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            key: 'farmhouse_food_safety'
          },
          {
            key: 'farmhouse_bathroom'
          },
          {
            key: 'farmhouse_room'
          }
        ]
      }
    })
    this.$forceUpdate()
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '房间名称',
            type: 'input',
            cols: 12,
            key: 'roomName',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '客房面积',
            type: 'inputNumber',
            cols: 12,
            key: 'roomArea',
            props: {
              min: 0
            }
          },
          {
            name: '分布楼层',
            type: 'inputNumber',
            cols: 12,
            key: 'floorDistribution',
            props: {
              min: 0
            }
          },
          {
            name: '每间最多入住人数',
            type: 'inputNumber',
            cols: 12,
            key: 'maximumOccupancy',
            props: {
              min: 0
            }
          },
          {
            name: '床型面积',
            type: 'inputNumber',
            cols: 12,
            key: 'bedTypeArea',
            props: {
              min: 0
            }
          },
          {
            label: '',
            type: 'text',
            cols: 12
          },
          {
            name: '床型',
            type: 'checkBoxButton',
            cols: 12,
            key: 'bedType',
            typeData: bedTypeArr(),
            styles: {
              marginBottom: '20px',
              width: '130px'
            }
          },
          {
            name: '窗户',
            type: 'radioButton',
            cols: 12,
            key: 'windowType',
            typeData: theWindow(),
            styles: {
              marginBottom: '20px'
            }
          },
          {
            name: '宽带情况',
            type: 'radioButton',
            cols: 12,
            key: 'broadband',
            typeData: broadbandSituation()
          },
          {
            name: '可否加床',
            type: 'radioButton',
            cols: 12,
            key: 'addBed',
            typeData: extraBed()
          },
          {
            name: '是否禁烟',
            type: 'radioButton',
            cols: 12,
            key: 'banSmoking',
            typeData: smoking()
          },
          {
            name: '无线WIFI',
            type: 'radioButton',
            cols: 12,
            key: 'wifiType',
            typeData: wirelessWIFI()
          }
        ]
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName'
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2'
            },
            {
              name: '公用标签库',
              value: '1'
            }
          ]
        },
        {
          name: '标签分类',
          type: 'iconClass',
          key: 'iconUrl'
        }
      ].filter(e => e)
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（尺寸750*420PX）限制1张
          </div>
        ),
        data: [
          {
            type: 'iconUpload',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'bannerUrl',
                desc: '<br /> 支持格式.png/.jpg'
              }
            ],
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm3() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              cols={i == 2 ? 24 : 12}
              style={{
                marginBottom: '10px',
                minHeight: '200px'
              }}
              icons={e.list}
            />
          )
        })
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            // 获取标签
            let arr = []
            this.labelGroup.forEach(item =>
              item.list.forEach(e => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            this.labelGroup.map(e => arr.push(...e.list))
            let brr = arr.map(e => ({
              ...e,
              czState: e.czState ? 1 : 0
            }))

            let upDown = this.detail.id ? this.detail.upDown : '0'

            let params = {
              ...data,
              banSmokingName: smoking().find(f => f.value == data.banSmoking)?.name || '',
              bedTypeName: bedTypeArr()
                .filter(e => data.bedType?.includes(e.value))
                .map(e => e.name)
                .toString(),
              broadbandName: broadbandSituation().find(f => f.value == data.broadband)?.name || '',
              wifiTypeName: wirelessWIFI().find(f => f.value == data.wifiType)?.name || '',
              windowTypeName: theWindow().find(f => f.value == data.windowType)?.name || '',
              addBedName: extraBed().find(f => f.value == data.addBed)?.name || '',
              bedType: data.bedType ? data.bedType.toString() : '',
              hotelId: this.hotelId,
              upDown: upDown,
              farmLabelDataDTOS: brr
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: `/hotelRoom/${this.detail.id ? 'update' : 'save'}`,
                params,
                isPost: false
              })
              .then(result => {
                this.$router.push('/commodityManagement/roomManage?id=' + this.hotelId)
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/commodityManagement/roomManage?id=' + this.hotelId)
          }
        }
      ]
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            putAction(`/hotelRoom/${this.detail.upDown == 0 ? 'downBatch' : 'upBatch'}`, {
              idList: [this.detail.id]
            }).then(e => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.push('/commodityManagement/roomManage?id=' + this.hotelId)
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPut.call(this, {
              url: `/hotelRoom/deleteBatch`,
              params: { idList: [this.detail.id] },
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/commodityManagement/roomManage?id=' + this.hotelId)
            }, 500)
          }
        }
      ]
      return {
        left,
        right: this.detail.id ? right : []
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getForm2(), this.getForm3()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
